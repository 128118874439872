import * as CheckboxPrimitive from '@radix-ui/react-checkbox'
import * as React from 'react'
import { cn } from '#app/utils/misc'

export type CheckboxProps = Omit<
	React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
	'type'
> & {
	type?: 'button' | 'submit' | 'reset'
	onCheckedChange?: (checked: any, value?: string) => void
}

const Checkbox = React.forwardRef<
	React.ElementRef<typeof CheckboxPrimitive.Root>,
	CheckboxProps
>(({ className, onCheckedChange, ...props }, ref) => {
	return (
		<CheckboxPrimitive.Root
			ref={ref}
			{...props}
			onCheckedChange={checked => {
				// Invoke the custom handler with the checked state and the value
				if (onCheckedChange) {
					onCheckedChange(checked, props.value as string)
				}
			}}
			className={cn(
				'peer h-6 w-6 shrink-0 rounded-sm border-[1.5px] bg-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=indeterminate]:border-[1.5px] data-[state=unchecked]:border-[1.5px]  data-[state=checked]:border-black-90  data-[state=unchecked]:border-beige-90 data-[state=checked]:text-black-90 ',
				className,
			)}
		>
			<CheckboxPrimitive.Indicator
				className={cn('flex items-center justify-center text-current')}
			>
				<svg viewBox="0 0 10 10" width="100%" height="100%">
					{/* Adjust viewBox dimensions */}
					<path
						d="M2,5 L4,7 L8,3"
						stroke="#000"
						strokeWidth="1.5"
						fill="none"
					/>
				</svg>
			</CheckboxPrimitive.Indicator>
		</CheckboxPrimitive.Root>
	)
})

Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
